/*eslint-disable */
// import GPathrRoadmapExtraModel from '../../Model/GPathrRoadmapExtra'
import request from '../../Utils/curl'

let GPathrRoadmapExtras = {
  /**
   * gpathRoadmapExtraList
   */
  async gpathRoadmapExtraList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_roadmap_extra_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapExtraList() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapExtraView
   */
  async gpathRoadmapExtraView (context, gpathRoadmapExtraId) {
    try {      
      let post_data = new FormData();
      post_data.append('gprme_id', gpathRoadmapExtraId);
      return await request.curl(context, "gpath_roadmap_extra_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpathRoadmapExtraView() and Exception:",err.message)
    }    
  },

  /**
   * gpathRoadmapExtraAdd
   */
  async gpathRoadmapExtraAdd (context, gpathRoadmapExtraObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpathRoadmapExtraObj) {
        post_data.append(key, gpathRoadmapExtraObj[key]);
      }

      return await request.curl(context, "gpath_roadmap_extra_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapExtraAdd() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapExtraEdit
   */
  async gpathRoadmapExtraEdit (context, gpathRoadmapExtraObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpathRoadmapExtraObj) {
        post_data.append(key, gpathRoadmapExtraObj[key]);
      }

      return await request.curl(context, "gpath_roadmap_extra_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapExtraEdit() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapExtraDelete
   */
  async gpathRoadmapExtraDelete (context, gpathRoadmapExtraId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gprme_id', gpathRoadmapExtraId);

      return await request.curl(context, "gpath_roadmap_extra_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpathRoadmapExtraDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathrRoadmapExtras
}
